export default {
  ama: {
    title: "AMA",
    short: "AMA Hosted",
    description: "Coinscope has hosted an AMA for the project",
    color: "#c0c0c0",
    type: "ama",
    id: "ama",
  },
  airdrop: {
    title: "Airdrop",
    short: "Airdrop Hosted",
    description: "Coinscope has organised an Airdrop for the project",
    color: "#7FFFD4",
    type: "airdrop",
    id: "airdrop",
  },
  vote5000: {
    value: 5000,
    title: "Voted",
    short: "5000 Votes",
    description: "Collect 5000 votes to unlock this badge",
    color: "#cd7f32",
    type: "vote",
    id: "vote5000",
  },
  audit: {
    title: "Audited",
    short: "Contract Audited",
    description: "Coinscope has audited the contract",
    color: "#4b62e7",
    type: "audit",
    id: "audit",
  },
  todaysBest: {
    title: "Time Gem",
    short: "Today's Best",
    description:
      "The most voted coin for one day in the today's best page. Today's best coin is also promoted for the next 24h!",
    color: "#55838b",
    type: "todaysBest",
    id: "todaysBest",
  },
  kyc: {
    title: "KYC Verified",
    short: "Verified Owners",
    description: "Coinscope verifies the identity of the project team members",
    color: "#196e1b",
    type: "kyc",
    id: "kyc",
  },
  unknown: {
    text: "",
    title: "",
    description: "",
    color: "#333333",
    id: "unknown",
  },
};

export const voteBadges = ["vote5000"];

export const applicableBadges = [
  "todaysBest",
  "audit",
  "vote5000",
  "kyc",
  "airdrop",
  "ama",
];
